import React from "react";
import hibernatingImg from "../images/hibernating-bear.jpg";

const Hibernating: React.FC = () => {
  return (
    <div className="flex flex-col items-center">
      <h1 className="text-6xl font-bold flex py-5">Away.</h1>
      <img
        src={hibernatingImg}
        alt="hibernating bear image"
        className="w-1/2"
      />
    </div>
  );
};

export default Hibernating;
